<template>
  <a-tree-select
    v-model="selectedValue"
    class="tree-select"
    :treeData="categoryTree"
    treeCheckable
    treeCheckStrictly
    :showCheckedStrategy="SHOW_ALL"
    :placeholder="$t('lada.categoryPlaceholder')"
    allowClear
    :size="controlsSize"
    @change="change"
  />
</template>

<script>
import TABLE_QUERY from '@/queries/table';
import { TreeSelect } from 'ant-design-vue';

const SHOW_ALL = TreeSelect.SHOW_ALL;

export default {
  name: 'CateoryTreeConfig',

  props: {
    controlsSize: {
      type: String,
      default: 'large',
    },
    categorySelected: {
      type: Array,
      default: () => [],
    },
  },

  apollo: {
    categoryList: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables: {
        type: 'Category',
        page: 0,
        pageSize: 999,
      },
      update({ table }) {
        return table.documents.map((el) => ({
          ...el.data,
          value: el.id,
          key: el.id,
          parentId: el.data?.parent?.value.split(':')[1],
        }));
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
  },

  data() {
    return {
      showConfig: false,
      SHOW_ALL,
      selectedValue: [],
    };
  },

  computed: {
    categoryTree() {
      if (!this.categoryList) return [];
      const list = this.categoryList;

      const map = {};
      let node;
      const roots = [];
      let i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
  },
  mounted() {
    this.selectedValue = this.categorySelected.map((category) => ({
      label: category.title,
      value: category.value.split(':')[1],
    }));
  },
  methods: {
    change(_a, _b, extra) {
      this.$emit('selectCategory', extra.checked, {
        value: `Category:${extra.triggerValue}`,
        title: extra.triggerNode.title,
      });
    },
  },
};
</script>

<style lang="scss">
.tree-select {
  width: 100%;
}
</style>
